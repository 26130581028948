import {useEffect, useState, useMemo, useRef} from 'react';
import {useNavigate, useLocation, Link as RLink} from 'react-router-dom';
import * as _ from 'lodash';
import data from '../assets/data.json';
import {
    Container,
    Paper,
    Box,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    Pagination,
    PaginationItem,
    Typography,
    Link, FormGroup, Checkbox
} from '@mui/material';

export const Practice = () => {
    const {search} = useLocation();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [timeOut, setTimeOut] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const initialized = useRef();

    const onSubmit = () => {
        setSubmitted(true);
        clearInterval(initialized.current);
        initialized.current = null;
    }

    useEffect(() => {
        const query = new URLSearchParams(search)
        const numberOfQuestion = Math.min((+query.get('s')), data.length);
        const timePerQuestion = +query.get('t');
        const startFrom = +query.get('f') % data.length;
        if (numberOfQuestion && timePerQuestion) {
            if (!initialized.current) {
                let q = [];
                if (query.get('f')) {
                    q = [
                        ...data.slice(startFrom, Math.min(startFrom + numberOfQuestion, data.length)),
                        ...data.slice(0, Math.max(0, startFrom + numberOfQuestion - data.length)),
                    ];
                    setAnswers(new Array(q.length).fill([]));
                    setQuestions(q);
                } else {
                    q = _.sampleSize(data, numberOfQuestion);
                    setAnswers(new Array(q.length).fill([]));
                    setQuestions(q);
                }


                const start = Date.now();
                let t = timePerQuestion * q.length;
                setTimeOut(t);
                initialized.current = setInterval(() => {
                    if (start + t * 1000 < Date.now()) {
                        onSubmit();
                    } else {
                        setTimeOut(Math.round(t + (start - Date.now()) / 1000));
                    }
                }, 1000);
            }
        } else {
            navigate('/', {replace: true});
        }
    }, [search]);

    const _currentQuestion = useMemo(() => questions[currentQuestion], [questions, currentQuestion])
    const onSelect = (question, single) => (e) => {
        const _answers = [...answers];
        if (single) {
            _answers[question] = [e.target.value];
            setAnswers(_answers);
            return;
        }
        if (e.target.checked) {
            _answers[question] = [..._answers[question], e.target.value];
        } else {
            _answers[question] = _answers[question].filter((a) => a !== e.target.value);
        }
        setAnswers(_answers);
    }

    const isCorrect = (answers, correctAnswers) => {
        return answers.length === correctAnswers.length && correctAnswers.every((v) => answers.includes(v));
    }

    const _renderItem = (item) => <PaginationItem {...item}
                                                  sx={
                                                      item.type === "page" && (submitted
                                                              ? (isCorrect(answers[item.page - 1], questions[item.page - 1].correctAnswer)
                                                                  ? {backgroundColor: '#a2cf6e'}
                                                                  : {backgroundColor: '#f73378'})
                                                              : (answers?.[item.page - 1]?.length > 0
                                                                  ? {backgroundColor: '#91ff35'}
                                                                  : {})
                                                      )
                                                  }/>
    const corrected = useMemo(() => answers.reduce((prev, curr, i) => prev + +(isCorrect(curr, questions[i].correctAnswer)), 0), [questions, answers]);
    return (
        <Container sx={{mt: 5}} className="do-not-select">
            <RLink to="/"><Link>Back</Link></RLink>
            <Paper sx={{px: 3, py: 2, mt: 3}} elevation={8}>
                <Box display='flex' alignItems="center" justifyContent="space-between">
                    <div />
                    <div>{String(Math.floor(timeOut / 60)).padStart(2, "0")}:{String(Math.floor(timeOut % 60)).padStart(2, "0")}</div>
                    {submitted ? <Typography color="primary">Corrected {corrected}/{questions?.length}</Typography> :
                        <Button variant='contained' onClick={onSubmit}>END</Button>}
                </Box>
                <Box sx={{my: 4}}>
                    <h4 style={{marginBottom: 0}}>{_currentQuestion?.questionContent}</h4>
                    <small>Ref: {_currentQuestion?.index}</small>

                    {_currentQuestion?.correctAnswer?.length > 1 && (
                        <FormGroup>
                            {_currentQuestion?.answer?.map((a) => {
                                return (
                                    <>
                                        <FormControlLabel key={a} control={<Checkbox
                                            checked={answers?.[currentQuestion]?.includes(a)}
                                            onChange={onSelect(currentQuestion)}
                                            value={a}
                                        />} label={a} />
                                    </>

                                )
                            })}
                        </FormGroup>
                    )}

                    {_currentQuestion?.correctAnswer?.length === 1 && (
                        <RadioGroup value={answers?.[currentQuestion]?.[0]} onChange={onSelect(currentQuestion, true)}>
                            {_currentQuestion?.answer?.map((a) => {
                                return (
                                    <FormControlLabel key={a} value={a} control={<Radio/>} label={a}/>
                                )
                            })}
                        </RadioGroup>
                    )}

                    {submitted && <h5>Correct:
                        {_currentQuestion?.correctAnswer?.map((a) => <p key={a} style={{color: 'green'}}>{a} </p>)}
                    </h5>}

                </Box>
                <Pagination boundaryCount={questions.length} count={questions.length} page={currentQuestion + 1}
                            onChange={(_, p) => setCurrentQuestion(p - 1)} shape="rounded" sx={{mt: 3}}
                            renderItem={_renderItem}
                />

            </Paper>
        </Container>
    );
}
